<template>
  <div class="main-carousel"
       :class="pending ? '_loading' : null">
    <div ref="carousel" class="main-carousel__wrap">
      <BasePreloader v-if="pending"/>
      <div class="main-carousel__list">
        <div v-for="item in slides"
             :key="item.id" class="main-carousel__item container">
          <picture v-if="$get(item.photo, 'image-xs')"
                   class="main-carousel__item-img-wrap">
            <source :srcset="$imgPlaceholder"
                    v-if="item.photo['image-l']"
                    :data-srcset="item.photo['image-l']"
                    media="(min-width: 1200px)"><!--1920-->
            <source :srcset="$imgPlaceholder"
                    v-if="item.photo['image-m']"
                    :data-srcset="item.photo['image-m']"
                    media="(min-width: 700px)"><!--1366-->
            <source :srcset="$imgPlaceholder"
                    v-if="item.photo['image-s']"
                    :data-srcset="item.photo['image-s']"
                    media="(min-width: 450px)"><!--768-->
            <img :src="$imgPlaceholder"
                 :data-src="item.photo['image-xs']"
                 :alt="item.title"
                 class="lazyload"><!--480-->
          </picture>
          <div class="main-carousel__item-content-wrap container-inner">
            <div class="main-carousel__item-content">
              <div class="main-carousel__item-label"
                   v-if="item.name">{{ item.name }}</div>
              <h2 class="main-carousel__item-title h1"
                  v-if="item.title">{{ item.title }}</h2>
              <div class="main-carousel__item-note"
                   v-if="item.text">{{ item.text }}</div>
              <template v-if="item.link">
                <a v-if="getLink(item.link).external"
                   :href="getLink(item.link).link"
                   class="main-carousel__item-btn base-btn">{{ item.link_description }}</a>
                <router-link v-else
                             :to="getLink(item.link).link"
                             class="main-carousel__item-btn base-btn">{{ item.link_description }}</router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-carousel__navs-wrap">
      <button ref="slide-prev"
              type="button"
              class="main-carousel__nav-btn base-btn _nav _prev">
        <BaseSvg name="ico-arrow-right"/>
      </button>
      <button ref="slide-next"
              type="button"
              class="main-carousel__nav-btn base-btn _nav _next">
        <BaseSvg name="ico-arrow-right"/>
      </button>
    </div>
  </div>
</template>

<script>
  import Swiper, {Navigation} from 'swiper/core'
  import getLink from '@/helpers/getLink'

  Swiper.use([Navigation])
  export default {
    name: 'MainCarousel',
    props: {
      slides: null,
      pending: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        swiper: null,
        getLink
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'main-carousel__list',
          slideClass: 'main-carousel__item',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          observer: true,
          speed: 600,
          navigation: {
            prevEl: this.$refs['slide-prev'],
            nextEl: this.$refs['slide-next'],
            disabledClass: '_disabled',
            hiddenClass: '_hidden',
            lockClass: '_hidden'
          }
        })
      }
    },
    mounted() {
      if (this.slides && this.slides.length) {
        this.createSwiper()
      }
    },
    watch: {
      slides() {
        if (this.swiper) {
          this.swiper.destroy()
        }
        this.$nextTick(() => {
          this.createSwiper()
        })
      }
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>
