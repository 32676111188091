<template>
  <div class="photo-gallery container" v-if="items && items.length">
    <div class="photo-gallery__inner container-inner">
      <CoolLightBox :items="items"
                    srcName="image_m"
                    :index="imageIndex"
                    :gallery="false"
                    @close="imageIndex = null"
                    closeOnClickOutsideMobile/>

      <div class="photo-gallery__head">
        <h3 class="photo-gallery__title">{{ $t('Фотогалерея') }}</h3>
        <div class="photo-gallery__nav-wrap">
          <div class="carousel-navs">
            <button class="carousel-navs__btn _prev"
                    ref="slide-prev"
            >
              <BaseSvg name="ico-arrow-long"
                       width="3rem"
                       height="3rem"/>
            </button>
            <button class="carousel-navs__btn _next"
                    ref="slide-next"
            >
              <BaseSvg name="ico-arrow-long"
                       width="3rem"
                       height="3rem"/>
            </button>
          </div>
        </div>
      </div>
      <div class="photo-gallery__carousel-wrap">
        <div ref="carousel" class="photo-gallery__carousel">
          <div class="photo-gallery__carousel-list">
            <div v-for="(item, index) in items"
                 :key="index"
                 class="photo-gallery__carousel-item"
            >
              <div class="photo-gallery__carousel-item-inner">
                <picture class="photo-gallery__img">
                  <img :src="$imgPlaceholder"
                       class="lazyload"
                       :data-src="item.image_xs" alt="image">
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper, {Navigation, Autoplay, Pagination} from 'swiper/core'
  import CoolLightBox from 'vue-cool-lightbox'

  Swiper.use([Navigation, Autoplay, Pagination])

  export default {
    name: 'PhotoGallery',
    components: {
      CoolLightBox
    },
    data() {
      return {
        swiper: null,
        imageIndex: null,
        pending: true,
        items: []
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'photo-gallery__carousel-list',
          slideClass: 'photo-gallery__carousel-item',
          slidesPerView: 'auto',
          roundLengths: true,
          speed: 600,
          loop: true,
          loopedSlides: 3,
          loopAdditionalSlides: 3,
          centerInsufficientSlides: true,
          threshold: 2,
          navigation: {
            prevEl: this.$refs['slide-prev'],
            nextEl: this.$refs['slide-next'],
            disabledClass: '_disabled',
            hiddenClass: '_hidden',
            lockClass: '_hidden',
          },
          autoplay: {
            delay: 3500,
            disableOnInteraction: false
          },
          pagination: {
            el: this.$refs['pagination'],
            type: 'fraction',
          },
          breakpoints: {
            768: {
              centeredSlides: true,
            }
          },
          init: false,
        })
        this.swiper.on('click', this.onSlideClick)
        this.swiper.init()
      },
      onSlideClick(swiper, event) {
        if(event.target.closest('.photo-gallery__carousel-item-inner')) {
          this.imageIndex = +swiper.clickedSlide.getAttribute('data-swiper-slide-index')
        }
      },
      load() {
        this.$store.dispatch('aboutUs/load').then((data) => {
          this.items = data.gallery && data.gallery.images || []
          this.$nextTick(this.createSwiper)
        }).finally(() => {
          this.pending = false
        })
      }
    },
    created() {
      this.load()
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>

<style>

</style>