var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"stickyPanelWrap",staticClass:"catalog__categories"},[_c('div',{staticClass:"catalog__categories-panel-wrap container",class:{_sticky: _vm.isSticky, _less: _vm.isLessHeight},style:(`top: ${_vm.headerHeight}px`)},[_c('div',{staticClass:"catalog__categories-panel container-inner"},[_c('div',{staticClass:"catalog__categories-wrap",class:{'_is-beginning': _vm.isBeginning, '_is-end': _vm.isEnd}},[(_vm.pending)?_c('BasePreloader'):_c('div',{ref:"carousel",staticClass:"catalog__categories-inner"},[_c('div',{staticClass:"categories"},_vm._l((_vm.listCategories),function(item,index){return _c('div',{key:index,staticClass:"categories__btn-wrap"},[_c('router-link',{staticClass:"categories__btn",class:{
                _active: _vm.isActiveLink(index, item.slug || item.id),
              },attrs:{"to":{
                name: 'category',
                params: { categoryId: item.slug || item.id },
                hash: '#catalog',
              }}},[_c('img',{staticClass:"lazyload categories__image",attrs:{"src":_vm.$imgPlaceholder,"data-src":item.image_xs,"alt":item.name}}),_c('span',{attrs:{"title":item.name}},[_c('span',[_vm._v(_vm._s(item.name))])])])],1)}),0)])],1),_c('div',{ref:"scroll",staticClass:"catalog__categories-scroll"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }