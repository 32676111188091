<template>
  <div class="catalog__categories"
       ref="stickyPanelWrap">
    <div class="catalog__categories-panel-wrap container"
         :style="`top: ${headerHeight}px`"
         :class="{_sticky: isSticky, _less: isLessHeight}">
      <div class="catalog__categories-panel container-inner">
        <div class="catalog__categories-wrap" :class="{'_is-beginning': isBeginning, '_is-end': isEnd}">
          <BasePreloader v-if="pending"/>
          <div v-else ref="carousel" class="catalog__categories-inner">
            <div class="categories">
              <div class="categories__btn-wrap"
                   v-for="(item, index) in listCategories"
                   :key="index">
                <router-link
                    :to="{
                  name: 'category',
                  params: { categoryId: item.slug || item.id },
                  hash: '#catalog',
                }"
                    :class="{
                  _active: isActiveLink(index, item.slug || item.id),
                }"
                    class="categories__btn"
                >
                  <img :src="$imgPlaceholder"
                       :data-src="item.image_xs"
                       class="lazyload categories__image" :alt="item.name"/>
                  <span :title="item.name">
                    <span>{{ item.name }}</span>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div ref="scroll" class="catalog__categories-scroll"></div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Swiper, {Scrollbar, Mousewheel} from 'swiper/core'
  import {languages} from '@/i18n'

  Swiper.use([Scrollbar, Mousewheel])

  export default {
    metaInfo() {
      return {
        ...this.metaComputed
      }
    },
    data() {
      return {
        swiper: null,
        isBeginning: false,
        isEnd: false,
        isSticky: false,
        timerCheck: null,
        timerResize: null,
        headerHeight: null,
        panelHeight: null,
        isLessHeight: false
      }
    },
    computed: {
      ...mapState('categories', ['pending', 'listCategories']),
      metaComputed() {
        if (!this.$route.params.categoryId) return
        const category = this.listCategories.find(item => item.slug === this.$route.params.categoryId || item.id === this.$route.params.categoryId)
        if (!category) return
        const categoryName = category.meta_title || category.name
        const description = category.meta_description

        const metaData = {
          title: categoryName,
          link: [
            {
              vmid: 'canonical',
              rel: 'canonical',
              href: `${window.location.origin}${this.$route.path}`
            }
          ],
          meta: [
            {
              vmid: 'og:title',
              property: 'og:title',
              content: categoryName
            },
            {
              vmid: 'twitter:title',
              name: 'twitter:title',
              content: categoryName
            }
          ]
        }

        if (description) {
          metaData.meta.push({
                vmid: 'description',
                name: 'description',
                content: description
              },
              {
                vmid: 'twitter:description',
                name: 'twitter:description',
                content: description
              }, {
                vmid: 'og:description',
                property: 'og:description',
                content: description
              })
        }

        let clearRoute = this.$route.path.slice(4)
        for (let langItem of languages) {
          metaData.link.push({
            vmid: `alternate_${langItem.code}`,
            rel: 'alternate',
            hreflang: langItem.code,
            href: `${window.location.origin}/${langItem.show_code}/${clearRoute}`
          })
        }

        return metaData
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'categories',
          slideClass: 'categories__btn-wrap',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          centerInsufficientSlides: true,
          speed: 600,
          observer: true,
          threshold: 2,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          observeParents: true,
          observeSlideChildren: true,
          init: false,
          freeMode: true,
          mousewheel: {
            forceToAxis: true
          },
          scrollbar: {
            el: this.$refs['scroll'],
            dragClass: 'catalog__categories-scroll-drag',
            lockClass: '_lock',
            draggable: true,
            snapOnRelease: false
          },
        })

        //this.swiper.on('progress', () => {
        //  this.chekProgress()
        //})
        this.swiper.init()
        //this.chekProgress()
      },
      chekProgress() {
        this.isBeginning = this.swiper.isBeginning
        this.isEnd = this.swiper.isEnd
      },
      isActiveLink(index, id) {
        return (
            (this.$route.name === 'home' || this.$route.name === 'category') && (index === 0 && !this.$route.params.categoryId) || id == this.$route.params.categoryId
        )
      },
      slideToNewIndex() {
        if (!this.swiper) return
        let newIndex = this.listCategories.findIndex(item => (item.slug || item.id) == this.$route.params.categoryId) || 0
        //let visibleSlidesIndexes = Array.from(this.swiper.visibleSlidesIndexes)
        //if (visibleSlidesIndexes.indexOf(newIndex) < 0) {
        //  this.swiper.slideTo(newIndex)
        //}

        this.swiper.slideTo(newIndex)
      },
      checkScroll() {
        let panelWrap = this.$refs.stickyPanelWrap || null
        let header = document.querySelector('.page-header__wrap') || null

        if (!panelWrap) {
          clearTimeout(this.timerCheck)
          this.timerCheck = setTimeout(() => {
            this.checkScroll()
          }, 500)
          return
        }
        let offsetY = panelWrap.getBoundingClientRect().y
        let panelHeight = panelWrap.clientHeight
        if (this.panelHeight !== panelHeight) {
          this.panelHeight = panelHeight
        }
        let headerHeight = header.clientHeight
        if (this.headerHeight !== headerHeight) {
          this.headerHeight = headerHeight
        }
        let offset = (offsetY - this.headerHeight)
        if (offset < 0) {
          if (!this.isSticky) {
            this.isSticky = true
          }
          if ((offset * -1) >= this.panelHeight) {
            if (!this.isLessHeight) {
              this.isLessHeight = true
            }
          } else if (this.isLessHeight) {
            this.isLessHeight = false
          }
        } else if (this.isSticky) {
          this.isSticky = false
          this.isLessHeight = false
        }
      },
      checkResize() {
        clearTimeout(this.timerResize)
        this.timerResize = setTimeout(() => {
          this.checkScroll()
        }, 500)
      }
    },
    created() {
      this.$store.dispatch('categories/load').then(() => {
        this.createSwiper()
        setTimeout(() => {
          this.slideToNewIndex()
        }, 300)
      })
      window.addEventListener('scroll', this.checkScroll)
      window.addEventListener('resize', this.checkResize)
    },
    mounted() {
      this.$nextTick(() => {
        this.checkScroll()
      })
    },
    watch: {
      '$route.params.categoryId'() {
        this.slideToNewIndex()
      }
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
      window.removeEventListener('scroll', this.checkScroll)
      window.removeEventListener('resize', this.checkResize)
    }
  }
</script>

<style lang="less">
</style>
