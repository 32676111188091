<template>
  <main class="page-home">
    <MainCarousel v-if="$get(settings, 'slider_top')"
                  :slides="topBanner"
                  :pending="pendingBanners"
    />
    <Catalog/>
    <MainCarousel v-if="$get(settings, 'slider_bottom')"
                  class="_promo"
                  :slides="promo"
                  :pending="pendingBanners"
    />
    <PopularBlock
        :title="$t('Суперпропозиція')"
        :products="popularProducts"
        :pending="pendingPopular"
        v-if="pendingPopular || !pendingPopular && popularProducts.length > 0"
    />
    <LastNews :title="$t('Останні новини')"
              :pending="pendingLastNews"
              :last-news="lastNews"
    />
    <PhotoGallery/>
  </main>
</template>

<script>
  import {http} from '@/axios'
  import MainCarousel from '../components/MainCarousel'
  import Catalog from '../components/Catalog'
  import PopularBlock from '../components/PopularBlock'
  import LastNews from '../components/LastNews'
  import PhotoGallery from '../components/PhotoGallery'
  import {mapState} from 'vuex'

  export default {
    name: 'Home',
    components: {
      LastNews,
      PopularBlock,
      Catalog,
      MainCarousel,
      PhotoGallery,
    },
    data() {
      return {
        popularProducts: [],
        pendingPopular: true,
        pendingLastNews: true,
        pendingBanners: true,
        lastNews: [],
        topBanner: [],
        promo: [],
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      ...mapState('categories', ['listCategories']),
      currentCategory() {
        if (!this.listCategories || !this.listCategories.length) return null

        let categoryId = this.$route.params.categoryId
        const category = this.listCategories.find(item => item.slug === categoryId || item.id === categoryId)

        if (category) {
          categoryId = category.id
        } else {
          categoryId = this.listCategories[0].id
        }

        return categoryId
      }
    },
    methods: {
      loadPopularProducts() {
        if (!this.currentCategory) return
        this.pendingPopular = true
        http.get(`/api/top-products?categories=${this.currentCategory}`)
            .then(({data}) => {
              this.popularProducts = data.data
            }).finally(() => {
          this.pendingPopular = false
        })
      },
      loadLastNews() {
        this.pendingLastNews = true
        http.get('/api/news/list', {
          params: {
            page: 1,
            limit: 10
          }
        }).then(({data}) => {
          this.lastNews = data.data
        }).finally(() => {
          this.pendingLastNews = false
        })
      },
      loadBanners() {
        if (!this.$get(this.settings, 'slider_top', false) && !this.$get(this.settings, 'slider_bottom', false)) {
          this.pendingBanners = false
          return
        }
        this.pendingBanners = true
        this.$store.dispatch('banners/load').then((data) => {
          this.topBanner = data.top
          this.promo = data.bottom
        }).finally(() => {
          this.pendingBanners = false
        })
      },
    },
    created() {
      this.loadPopularProducts()
      this.loadLastNews()
      this.loadBanners()
    },
    watch: {
      listCategories(to) {
        if (!this.currentCategory && to && to.length) {
          this.loadPopularProducts()
        }
      },
      currentCategory() {
        this.loadPopularProducts()
      }
    }
  }
</script>
