<template>
  <div class="last-news container">
    <div class="last-news__inner container-inner">
      <h3 class="last-news__title">{{ title }}</h3>
      <div class="last-news__carousel">
        <div ref="carousel" class="last-news__wrap" :class="{_loading: pending}">
          <BasePreloader v-if="pending"/>
          <div class="last-news__list">
            <EventCard
                v-for="item in lastNews"
                :key="item.id"
                :event="item"
                class="last-news__item"
            />
          </div>
        </div>
        <div class="last-news__navs-wrap">
          <div class="last-news__navs-inner container-inner">
            <button ref="slide-prev"
                    type="button"
                    class="last-news__nav-btn base-btn _nav _prev">
              <BaseSvg name="ico-arrow-right"/>
            </button>
            <button ref="slide-next"
                    type="button"
                    class="last-news__nav-btn base-btn _nav _next">
              <BaseSvg name="ico-arrow-right"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper, {Navigation} from 'swiper/core'
  import EventCard from './EventCard'

  Swiper.use([Navigation])
  export default {
    name: 'LastNews',
    components: {EventCard},
    props: {
      title: String,
      pending: Boolean,
      lastNews: null
    },
    data() {
      return {
        swiper: null
      }
    },
    methods: {
      createSwiper() {
        this.swiper = new Swiper(this.$refs['carousel'], {
          wrapperClass: 'last-news__list',
          slideClass: 'last-news__item',
          slidesPerView: 'auto',
          watchOverflow: true,
          roundLengths: true,
          observer: true,
          observeSlideChildren: true,
          speed: 600,
          navigation: {
            prevEl: this.$refs['slide-prev'],
            nextEl: this.$refs['slide-next'],
            disabledClass: '_disabled',
            hiddenClass: '_hidden',
            lockClass: '_hidden'
          }
        })
      },
    },
    watch: {
      lastNews() {
        this.createSwiper()
      }
    },
    beforeDestroy() {
      if (this.swiper) {
        setTimeout(() => {
          this.swiper.destroy()
        }, 1000)
      }
    }
  }
</script>
