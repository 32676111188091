<template>
  <div class="catalog has-anchor" id="catalog">
    <CatalogCategories/>
    <div class="catalog__wrap container">
      <div class="catalog__inner container-inner">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogCategories from "./CatalogCategories";

export default {
  name: "Catalog",
  components: {
    CatalogCategories
  },
  computed: {}
};
</script>

<style>
</style>
