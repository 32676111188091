<template>
  <article class="news-card">
    <router-link :to="route" class="news-card__img-wrap">
      <picture>
        <source :srcset="$imgPlaceholder"
                :data-srcset="event.image_s"
                media="(min-width: 500px)"><!--768-->
        <img :src="$imgPlaceholder"
             class="lazyload"
             :data-src="event.image_xs" :alt="event.name"><!--480-->
      </picture>
      <div class="news-card__views views-element">
        <BaseSvg name="ico-eye"/>
        <span>{{ event.views }}</span>
      </div>
    </router-link>

    <div class="news-card__content">
      <h4 class="news-card__title" :title="event.name">
        <router-link :to="route">
          {{ event.name }}
        </router-link>
      </h4>
      <div class="news-card__footer">
        <div class="news-card__footer-inner">
          <time :datetime="event.date | formatDateForTimeTag" class="news-card__date">{{ event.date | formatDate }}
          </time>
          <div v-for="(item, index) in event.tags" :key="index" class="news-card__tag">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    event: null
  },
  computed: {
    route() {
      return {
        name: 'event',
        params: {
          categoryId: this.event.category && this.event.category.slug || 'news',
          id: this.event.slug
        }
      };
    }
  }
};
</script>
