<template>
  <div v-if="error || !pending && !items.length" class="catalog__not-found">
    <BaseSvg name="ico-sad"/>
    <div class="catalog__not-found-title">{{ error && error.message || $t('Товарів в даній категорії не знайдено')}}</div>
    <div class="catalog__not-found-text">{{ $t('Виберіть іншу категорію') }}</div>
  </div>
  <div v-else class="catalog__list-wrap">
    <BasePreloader v-if="pending"/>
    <div  class="catalog__list">
      <Card
          class="catalog__item"
          v-for="item in items"
          :key="item.id"
          :card="item"
      />
    </div>
    <Pagination
        v-if="totalPages > 1"
        :totalPages="totalPages"
        :activePages="loadedPages"
        :hash="'#catalog'"
        withMore
        :pendingMore="pendingMore"
        @more="onLoadMore"
        class="catalog__pagination"
    />
  </div>
</template>

<script>
  import Card from './Card'
  import Pagination from './Pagination'
  import {http} from '@/axios'

  export default {
    components: {
      Card,
      Pagination,
    },
    data() {
      return {
        pending: false,
        pendingMore: false,
        items: [],
        loadedPages: [],
        totalPages: 1,
        swiper: null,
        error: null
      }
    },
    computed: {
      categoryId() {
        return this.$route.params.categoryId
      },
      listCategories() {
        return this.$store.state.categories.listCategories
      },
      categories() {
        return this.$store.state.categories.listCategories
      },
      defaultCategoryId() {
        return this.listCategories && this.listCategories[0].slug || null
      },
      currentPage() {
        return parseFloat(this.$route.query.page) || 1
      },
    },
    methods: {
      load() {
        this.error = null
        const isMore = !!this.$route.params.loadMore
        if (isMore) {
          this.pendingMore = true
        } else {
          this.pending = true
        }

        const id = this.categoryId || this.defaultCategoryId
        http
            .get(`/api/category_products/${id}`, {
              params: {
                page: this.currentPage,
                limit: this.getLimit(),
              },
            })
            .then(({data}) => {
              if (isMore) {
                this.items = this.items.concat(data.data)
                this.loadedPages.push(this.currentPage)
              } else {
                this.items = data.data
                this.loadedPages = [this.currentPage]
              }

              this.totalPages = data.meta.last_page
            })
            .catch(({response}) => {
              this.error = response.data
            })
            .finally(() => {
              this.pending = false
              this.pendingMore = false
            })
      },
      getLimit() {
        return 20
      },
      onLoadMore() {
        const newPage = this.currentPage + 1
        this.$router.push({
          query: {page: newPage},
          params: {loadMore: true, savePosition: true},
        })
      }
    },
    mounted() {
      this.$store.dispatch('categories/load').then(() => {
        this.load()
      })
    },
    watch: {
      categoryId() {
        this.load()
      },
      currentPage() {
        this.load()
      },
    },
  }
</script>

<style lang="less">
</style>
